import React from "react"
import KeyIcon from '@mui/icons-material/Key';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import QueryStatsIcon from '@mui/icons-material/QueryStats';


export const SideBarData = [
    {
        title: "Order Keys",
        icon: <ShoppingCartIcon />,
        link: "/buykeys",
        requiresAdmin: false,
        showAdmin: true,
    },
    {
        title: "Manage Orders",
        icon: <ShoppingBagIcon />,
        link: "/manageorders",
        requiresAdmin: false,
        showAdmin: true,
    },
    {

        title: "Manage Keys",
        icon: <KeyIcon />,
        link: "/managekeys",
        requiresAdmin: false,
        showAdmin: true,
    },
    {
        title: "User Settings",
        icon: <ManageAccountsIcon />,
        link: "/usersettings",
        requiresAdmin: true,
        showAdmin: true,
    },
    {
        title: "Product Settings",
        icon: <InventoryIcon />,
        link: "/productsettings",
        requiresAdmin: true,
        showAdmin: true,
    },
    {
        title: "SEO",
        icon: <QueryStatsIcon />,
        link: "/seo",
        requiresAdmin: true,
        showAdmin: true,
    },
    {
        title: "Settings",
        icon: <SettingsIcon />,
        link: "/settings",
        requiresAdmin: false,
        showAdmin: false,
    },

    {
        title: "Logout",
        icon: <LogoutIcon />,
        link: "/logout",
        requiresAdmin: false,
        showAdmin: true,
    }
]