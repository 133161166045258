import React, { useState } from "react";
import "../App.css";
import { SideBarData } from './data/SidebarData';
import { Link, useLocation } from "react-router-dom";
import myTheme from "./themes/mui-theme";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {ThemeProvider} from "@mui/material/styles";
import axios from "axios";
import baseLink from "./data/api-base-link";

function Sidebar({ isAdmin, setIsOpen, isOpen, setSuccess, setError, token }) {
    const location = useLocation();
    const [reportWindow, setReportWindow] = useState(false);
    const [problem, setProblem] = useState("");

    const filteredData = SideBarData.filter(item => {
        if (item.requiresAdmin) {
            return isAdmin
        }
        if (!item.showAdmin) {
            if (isAdmin) {
                return false;
            }
        }
        return true;
    });


    const handleReportWindow = () => {
        setReportWindow(!reportWindow);
    }

    const handleSetProblem = (event) => {
        setProblem(event.target.value);
    }

    const handleSubmitReport = async () => {
        try {
            if (problem === "") {
                setError("Cannot submit an empty report")
                return
            }
            const response = await axios.post(`https://${baseLink}/report/bug/${token}?nocache=`, {"report":problem});
            if (response.data) {
                if (!(response.data.startsWith("Key not found"))) {
                }
                setSuccess(response.data)
                setReportWindow(false)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Login token expired, please login again.");
                    setReportWindow(false)
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                    setReportWindow(false)
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                } else if (error.response.status === 400) {
                    setError(error.response.data);
                } else {
                    setError("An unexpected error occurred.");
                }
            }
        }
    }

    const handleRebootRequest = async () => {
        try {
            const response = await axios.get(`https://${baseLink}/reboot/${token}?nocache=`, {"report":problem});
            if (response.data) {
                setSuccess(response.data)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Login token expired, please login again.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                } else if (error.response.status === 400) {
                    setError(error.response.data);
                } else {
                    setError("An unexpected error occurred.");
                }
            }
        }
    }

    const handleClick = (item) => {
        if (isOpen) {
            setIsOpen(false)
        }
    };



    return (
        <div className={`Sidebar ${isOpen ? "open" : ""}`}>
            <div className={"background"}></div>

            <ul className="SidebarList">
                <h2>Desync Panel</h2>
                {filteredData.map((val, key) => (
                    <Link to={val.link} key={key} activeClassName="active" style={{textDecoration: 'none'}}>
                        <li onClick={handleClick} className="row" id={location.pathname === val.link ? "active" : ""}  /* Disable dragging */>
                            <div id="icon">{val.icon}</div>
                            <div id="title">{val.title}</div>
                        </li>
                    </Link>
                ))}
                <li style={{
                    justifyContent: "center",
                    alignItems: "center", display: "flex"
                }}>
                    <button onClick={handleReportWindow}>Report a Problem</button>
                </li>
                {isAdmin && (
                    <li style={{
                        justifyContent: "center",
                        alignItems: "center", display: "flex"
                    }}>
                        <button onClick={handleRebootRequest}>Reboot Server</button>
                    </li>
                )}

            </ul>
            {reportWindow && (
                <div className="popup">
                    <div className="popup-content-large">
                        <span className="close-btn" onClick={handleReportWindow}>&times;</span>
                            <div className="vSort">
                                <div style={{display:"flex", justifyContent:'center', alignItems:"center"}}>
                                <p>Have an issue? <em> Let us know.</em></p>
                                </div>
                                <div>
                                    <p><em>1.</em> Describe the issue in detail<br/><em>2.</em> Explain how to replicate the issue<br/><em>3.</em> If possible, send a link to a video of the issue.</p>
                                </div>
                                <div style={{display:"flex", justifyContent:'left', alignItems:"center"}}>
                                    <ThemeProvider theme={myTheme}>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': {
                                                    m: 1,
                                                    width: '370px',
                                                    color: "white",
                                                    backgroundColor: myTheme.palette.background.main,
                                                },
                                                '& input': {
                                                    color: 'white', // Set text color to white
                                                },
                                                '& .MuiInputBase-inputMultiline': {
                                                    color: 'white', // Set text color to white for multiline input
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                    },
                                                }, maxHeight: '200px',
                                                overflow: 'auto'

                                            }}
                                            noValidate
                                            autoComplete="off">
                                            <TextField variant={"outlined"} color={"primary"} value={problem} multiline label={"Issue or Bug"} placeholder={"Please describe the problem in detail..."} onChange={(text) => handleSetProblem(text)} focused />
                                        </Box>
                                    </ThemeProvider>                                </div>
                                <div>
                                    <button onClick={handleSubmitReport}>Submit</button>
                                </div>
                            </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Sidebar;
