import React, { useState, useEffect } from 'react';
import "../../App.css"
import axios from "axios";
import TextFieldComp from "../TextFieldComponent";
import SelectComp from "../SelectComponent";
import baseLink from "../data/api-base-link";
import {IconButton} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ToolTip from "../ToolTip";
import ToolTipNoIcon from "../utils/ToolTipNoIcon";


function ManageKeys({isAdmin, token, keyData, setError, setSuccess, userData}) {
    const [searchKey, setSearchKey] = useState("")
    const [selectedKey, setSelectedKey] = useState("")
    const [keyMenuItems, setKeyMenuItems] = useState({})

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageDict, setPageDict] = useState({})
    const [selectedPage, setSelectedPage] = useState(1)
    const [pageNums, setPageNumDict] = useState({})




    const handleSetItemsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        setSelectedPage(1);
        setSelectedKey("")

        if (isNaN(value) || value <= 0) {
            setItemsPerPage(1); // Default to 1 if the value is not a valid non-zero integer
        } else {
            setItemsPerPage(value);
        }
    };


    const handleSearchHWIDReset = async (selectedkey) => {
        if (selectedkey === "") {
            setError("Must select a key for HWID Reset")
            return
        }
    try{
        const response = await axios.get(`https://${baseLink}/key/reset/` + selectedkey + "/" + "HWID Reset" + "/" + token + "?nocache=");
        if (response.data) {
            if (!(response.data.startsWith("Key not found"))) {
            }
            setSuccess(response.data)
        }
    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                setError("Invalid username or password.");
            } else if (error.response.status === 500) {
                setError("Server error. Please try again later.");
            } else if (error.response.status === 429) {
                setError("Too many requests, please wait 1 minute.");
            } else if (error.response.status === 400) {
                setError(error.response.data);
            } else {
                setError("An unexpected error occurred.");
            }
        }
    }
};

    const handleHWIDReset = async () => {
    try{
            if (selectedKey === "") {
                setError("Must input a Key for HWID Reset")
                return
            }

            const response = await axios.get(`https://${baseLink}/key/reset/` + selectedKey + "/" + "HWID Reset" + "/" + token + "?nocache=");
            if (response.data) {
                if (!(response.data.startsWith("Key not found"))) {
                }
                setSuccess(response.data)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Invalid username or password.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                } else if (error.response.status === 400) {
                    setError(error.response.data);
                } else {
                    setError("An unexpected error occurred.");
                }
            }
        }
    };

    const handleSearchKeyFreeze = async (selectedkey) => {
        if (selectedkey === "") {
            setError("Must select a key to freeze")
            return
        }

        try {

            const response = await axios.get(`https://${baseLink}/key/freeze/` + selectedkey + "/" + "Panel Freeze" + "/" + token + "?nocache=");
            if (response.data) {
                if (!(response.data.startsWith("Key not found"))) {
                }
                setSuccess(response.data);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Invalid username or password.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };

    const handleKeyFreeze = async () => {
        try {
            if (selectedKey === "") {
                setError("Must select a key for HWID reset")
                return

            }
            const response = await axios.get(`https://${baseLink}/key/freeze/` + selectedKey + "/" + "Panel Freeze" + "/" + token + "?nocache=");
            if (response.data) {
                if (!(response.data.startsWith("Key not found"))) {
                }
                setSuccess(response.data);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Invalid username or password.");
                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                } else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };

    const handleSearchKey = (event) => {
        setSelectedPage(1);
        setSearchKey(event.target.value);
        let keysList = [];

        // Flatten the keyData to create a list of all keys with additional properties
        for (const user in keyData) {
            for (const order in keyData[user]) {
                const orderDetails = keyData[user][order];
                // Iterate over each key in the orderDetails
                for (const [kLength, keyList] of Object.entries(orderDetails.keys)) {
                    for (const productKey of keyList) {
                        if (productKey !== "Cannot generate 0 keys" && !(productKey.startsWith("Not enough keys"))) {
                            keysList.push({
                                key: productKey,
                                keylength: kLength,
                                orderId: order,
                                userId: user,
                                product: orderDetails.product,
                                date: orderDetails.date
                            });
                        }
                    }
                }
            }
        }

        // Filter the keysList based on the search input
        const searchTerm = event.target.value.toLowerCase();
        const filteredKeysList = keysList.filter(item =>
            item.key.toLowerCase().includes(searchTerm) || // Search by productKey
            item.product.toLowerCase().includes(searchTerm) || // Search by product
            item.orderId.toLowerCase().includes(searchTerm) // Search by orderId
        );

        // Sort the filteredKeysList by date
        filteredKeysList.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Total length of filtered keys
        let totallen = filteredKeysList.length;

        let newPageDict = {};
        let pageNum = 1;
        let pageNumDict = {};

        // Paginate the filtered keys
        for (let i = 0; i < totallen; i += itemsPerPage) {
            newPageDict[pageNum] = filteredKeysList.slice(i, i + itemsPerPage);
            pageNumDict[pageNum] = pageNum;
            pageNum++;
        }

        setPageNumDict(pageNumDict);
        setPageDict(newPageDict);
    };

    const formatOrderDate = (timestamp) => {
        const now = new Date();
        const orderDate = new Date(timestamp);

        const timeDiff = now - orderDate; // Time difference in milliseconds
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Days difference

        const options = { hour: '2-digit', minute: '2-digit' }; // For formatting time
        const formattedTime = orderDate.toLocaleTimeString([], options);

        if (daysDiff < 1) {
            return `Today, ${formattedTime}`;
        } else if (daysDiff === 1) {
            return `Yesterday, ${formattedTime}`;
        } else if (daysDiff < 7) {
            return `${daysDiff} Days Ago, ${formattedTime}`;
        } else {
            const weeksDiff = Math.floor(daysDiff / 7);
            const formattedDate = orderDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
            return `${weeksDiff} Week${weeksDiff > 1 ? ("s") : ("")} Ago, ${formattedDate}`;
        }
    };

    const handleCopy = (text, copyMessage) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSuccess(copyMessage);
                // You can add a success message or other feedback here if needed
            })
            .catch((err) => {
                setError('Failed to copy to clipboard');
            });
    };

    const handleSetSelectedKey = (key) => {
        setSelectedKey(key)
    }

    const handleSetPageNum = (event) => {
        setSelectedPage(event.target.value)
        setSelectedKey("")
    }


    useEffect(() => {
        let keysList = [];

        // Flatten the keyData to create a list of all keys with additional properties

        for (const user in keyData) {
            for (const order in keyData[user]) {
                const orderDetails = keyData[user][order];
                // Iterate over each key in the orderDetails

                if ("keys" in orderDetails) {
                    for (const [kLength, keyList] of Object.entries(orderDetails.keys)) {

                        for (const productKey of keyList) {
                            if (!(productKey === "Cannot generate 0 keys") && !(productKey.startsWith("Not enough keys"))) {
                                keysList.push({
                                    key: productKey,
                                    keylength: kLength,
                                    orderId: order,
                                    userId: user,
                                    product: orderDetails.product,
                                    date: orderDetails.date
                                });
                            }
                        }
                    }
                }
            }
        }

        // Sort the keysList by date
        keysList.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Total length of keys
        let totallen = keysList.length;

        let newPageDict = {};
        let pageNum = 1;
        let pageNumDict = {};

        // Paginate the keys
        for (let i = 0; i < totallen; i += itemsPerPage) {
            newPageDict[pageNum] = keysList.slice(i, i + itemsPerPage);
            pageNumDict[pageNum] = pageNum;
            pageNum++;
        }

        setPageNumDict(pageNumDict);
        setPageDict(newPageDict);

    }, [itemsPerPage, selectedPage, keyData]);



    return (
        <div className="PageDefaults">
            <div className={"BoxItemFlexBox"}>
                <div className="KeyManage">
                    <div className={"InputKey"}>
                        <div className={"BoxItem keyMenu"}>
                            <div className={"keySearch"}>
                                <div className={"toolTipContainer"}>
                                    <p>Search for Key</p>
                                    <ToolTip id={"tt"} tooltipText={"You can reset/freeze any key you input here without selecting it below"}/>
                                </div>

                                <TextFieldComp label={"Search"} type={"text"} onChangeFunc={handleSearchKey}/>
                                {searchKey && (
                                    <div className={"vSort"} style={{marginTop: "-1ch", marginBottom: "2ch"}}>
                                        <div>
                                            <button onClick={() => handleSearchHWIDReset(searchKey)}>Reset Search Key</button>
                                        </div>
                                        <div>
                                            <button onClick={() => handleSearchKeyFreeze(searchKey)}>Freeze Search Key</button>
                                        </div>
                                    </div>
                                )}
                                <div className={"hSort"}>
                                    <SelectComp label={"Page #"} onChangeFunc={handleSetPageNum} menuItems={pageNums}
                                                selectWidth={"11ch"} value={selectedPage}/>
                                    <TextFieldComp label={"Items/Page"} type={"number"}
                                                   onChangeFunc={handleSetItemsPerPage} boxWidth={"11ch"}
                                                   value={itemsPerPage}/>

                                </div>
                            </div>

                            <>
                                {selectedKey ? (
                                    <>
                                        <div className={"keySearch"}>
                                            <p>Key Actions</p>

                                            <p><em>Key: <br/></em>{selectedKey}</p>
                                            <div className={"buttons"}>
                                                <div>
                                                <button onClick={handleHWIDReset}>HWID Reset</button>
                                                </div>
                                                <div>
                                                    <button onClick={handleKeyFreeze}>Freeze Key</button>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                ) : (
                                    <p>Please select a key from the list below.</p>
                                )}
                            </>
                        </div>

                        <div className={"KeyScrollContainer"}>
                            <>
                                {pageDict && !(Object.values(pageDict).length === 0) && pageDict[selectedPage].map((value, index) => (
                                    <>
                                        <div
                                            className={selectedKey && selectedKey.startsWith(value.key) ? ("BoxItem keyBox active") : ("BoxItem keyBox")}
                                            onClick={() => handleSetSelectedKey(value.key)}>
                                            <div className={"topFlex"}>
                                                <div className={"orderID"}>
                                                    <h2 className={"keyDeco"}>
                                                        <em>Key:</em> {value.key}
                                                        <IconButton className="copyButton"
                                                                    onClick={() => handleCopy(value.key, "Key copied to clipboard")}
                                                                    size={"small"}>
                                                            <ContentCopyIcon sx={{
                                                                fontSize: 20,
                                                                color: "white",
                                                                backgroundColor: "transparent"
                                                            }}/>
                                                        </IconButton>
                                                    </h2>
                                                </div>
                                                <div className={"orderDate"}>
                                                    <ToolTipNoIcon tooltipText={value.date}>
                                                        <p className={"keyDeco"}>
                                                            {formatOrderDate(value.date)}
                                                            <IconButton className="copyButton"
                                                                        onClick={() => handleCopy(value.date, "Timestamp copied to clipboard")}
                                                                        size={"small"}>
                                                                <ContentCopyIcon sx={{
                                                                    fontSize: 20,
                                                                    color: "white",
                                                                    backgroundColor: "transparent"
                                                                }}/>
                                                            </IconButton></p>
                                                    </ToolTipNoIcon>

                                                </div>
                                            </div>
                                            {isAdmin && (
                                                <div>
                                                    <p className={"keyDeco"}><em>User: </em>

                                                        {userData[value.userId].avatar && (
                                                            <img src={userData[value.userId].avatar}
                                                                 alt={"asd"}
                                                                 loading="lazy"/>)

                                                        }

                                                        {(userData[value.userId].dusername !== "N/A" && userData[value.userId].dusername) || value.userId}
                                                        <IconButton className="copyButton"
                                                                    onClick={() => handleCopy(value.userId, "User ID copied to clipboard")}
                                                                    size={"small"}>
                                                            <ContentCopyIcon sx={{
                                                                fontSize: 20,
                                                                color: "white",
                                                                backgroundColor: "transparent"
                                                            }}/>
                                                        </IconButton>
                                                    </p>
                                                </div>
                                            )}
                                            <div className={"orderDetails"}>
                                                <div>
                                                    <p className={"keyDeco"}><em>Product:</em> {value.product}</p>
                                                </div>
                                                <div>
                                                    <p className={"keyDeco"}><em>Length:</em> {value.keylength}</p>
                                                </div>

                                                <div>
                                                    <p className={"keyDeco"}><em>Order ID:</em> {value.orderId}
                                                        <IconButton className="copyButton"
                                                                    onClick={() => handleCopy(value.orderId, "Order ID copied to clipboard")}
                                                                    size={"small"}>
                                                            <ContentCopyIcon sx={{
                                                                fontSize: 20,
                                                                color: "white",
                                                                backgroundColor: "transparent"
                                                            }}/>
                                                        </IconButton>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>


                                    </>
                                ))}
                                {/*
                            {Object.entries(keyData).map(([user, orders], index) => (
                            <>
                                {orders && !(orders === {}) && Object.entries(orders).map(([orderIdD, orderData], index) => (
                                    <>

                                        {Object.entries(orderData.keys).map(([length, keyList], index) => (
                                            <>
                                                {keyList.map((value, index) => (
                                                    <div
                                                        className={selectedKey && selectedKey === value ? ("BoxItem keyBox active") : ("BoxItem keyBox")}
                                                        onClick={() => handleSetSelectedKey(value)}>
                                                        <div className={"spacing"}>
                                                            <p className={"keyDeco"}>{value}</p>
                                                        </div>
                                                        <IconButton className="copyButton"
                                                                    onClick={() => handleCopy(value)}
                                                                    size={"small"}>
                                                            <ContentCopyIcon sx={{
                                                                fontSize: 20,
                                                                color: "white",
                                                                backgroundColor: "transparent"
                                                            }}/>
                                                        </IconButton>

                                                        <div>
                                                            <p className={"keyDeco"}>
                                                                <em>Product: </em>: {orderData.product}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ))}
                                    </>
                                ))}
                                    </>
                                ))}
                                */}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageKeys;